import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useLayoutEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import contactStyles from "./contact.styles";
import HomePoster from "../../components/header/HomePoster/HomePoster";
import Swal from "sweetalert2";
function Contacts() {
  const classes = contactStyles;
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    // Function to update screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change 768 to the desired breakpoint
      setIsTab(window.innerWidth < 1080);
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^[0-9]{10,11}$/;
    return phoneRegex.test(number);
  };

  const sendEnquiry = async () => {
    if (!validateEmail(email)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!validatePhoneNumber(contact)) {
      Swal.fire({
        title: "Invalid Phone Number",
        text: "Please enter a 10-digit phone number.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://backendapi.axisinfoline.com/enquiry/gaanvwala/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            contact,
            message,
          }),
        }
      );

      if (response.ok) {
        // Display success message
        Swal.fire({
          title: "Success!",
          text: "Your enquiry has been sent successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setEmail("");
        setName("");
        setMessage("");
        setContact("");
      } else {
        // Display error message if response is not ok
        Swal.fire({
          title: "Error",
          text: "Something went wrong. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      // Handle any other errors
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
    setIsLoading(false);
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box style={{ position: "relative" }}>
            <img
              style={{ width: "100% ", height: 250, background: "#0F1B31" }}
              src="./images/bannerImg.png"
            />
            <Box
              style={{
                position: "absolute",
                textAlign: "center",
                width: "100%",
                height: "100%",
                top: "0px",
                left: "0px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: "700",
                  fontSize: isTab ? "24px" : "60px",
                  lineHeight: isTab ? "36px" : "62.6px",
                  color: "white",
                }}
              >
                CONTACT US
              </Typography>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: "400",
                  fontSize: isTab ? "20px" : "30px",
                  lineHeight: "37.8px",
                  color: "#fff",
                  marginTop: "10px",
                }}
              >
                HOME{" "}
                <span
                  style={{
                    fontSize: "30px",
                    padding: "0px 0px 6px 6px",
                    fontWeight: "bolder",
                  }}
                >
                  →
                </span>{" "}
                CONTACT US
              </Typography>
              {/* <Typography style={{fontSize: '15px', color: '#fff'}}>Home→ MISSION & VISION</Typography> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        px={isTab ? "20px" : "50px"}
        py={isTab ? "20px" : "40px"}
        mt={isTab ? 0 : 6}
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={isMobile ? 12 : 6}>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: "400",
                fontSize: isTab ? "20px" : "30px",
                lineHeight: "37.8px",
                color: "#1377C0",
              }}
            >
              GET IN TOUCH
            </Typography>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: "700",
                fontSize: isTab ? "24px" : "50px",
                lineHeight: isTab ? "36px" : "62.6px",
              }}
            >
              Don’t Hesitate to Contact Us
            </Typography>
          </Box>
          <div style={{ marginTop: "30px" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",

                border: "1px solid #ECF1F9",
                padding: "10px 16px",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <img
                src="./images/new-location.png"
                style={{ width: "50px", height: "50px" }}
              />
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "20px",
                    fontFamily: "Outfit",
                    fontWeight: "400",
                  }}
                >
                  Our Address
                </p>
                <h2
                  style={{
                    fontSize: isTab ? "20px" : "26px",
                    fontFamily: "Outfit",
                    fontWeight: "600",
                    margin: "0px",
                  }}
                >
                  Girdharipura, Jaipur India, 302021
                </h2>
              </div>
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",
                width: "100%",
                border: "1px solid #ECF1F9",
                padding: "10px 16px",
                borderRadius: "8px",
              }}
            >
              <img
                src="./images/new-call.png"
                style={{ width: "50px", height: "50px" }}
              />
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "20px",
                    fontFamily: "Outfit",
                    fontWeight: "400",
                  }}
                >
                  Phone Number
                </p>
                <h2
                  style={{
                    fontSize: isTab ? "20px" : "26px",
                    fontFamily: "Outfit",
                    fontWeight: "600",
                    margin: "0px",
                  }}
                >
                  +91 &nbsp;89055 &nbsp;34304
                </h2>
              </div>
            </div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",
                width: "100%",
                border: "1px solid #ECF1F9",
                padding: "10px 16px",
                borderRadius: "8px",
              }}
            >
              <img
                src="./images/new-mail.png"
                style={{ width: "50px", height: "50px" }}
              />
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "20px",
                    fontFamily: "Outfit",
                    fontWeight: "400",
                  }}
                >
                  Email
                </p>
                <h2
                  style={{
                    fontSize: isTab ? "20px" : "26px",
                    fontFamily: "Outfit",
                    fontWeight: "600",
                    margin: "0px",
                    textWrap: isMobile ? "wrap" : "nowrap",
                    wordBreak: "break-all",
                  }}
                >
                  info@gaanvwala.com
                </h2>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          container
          xs={12}
          sm={isMobile ? 12 : 5}
          display="flex"
          borderRadius="8px"
          padding={isMobile ? "20px" : "30px 30px"}
          ml={isTab ? "12px" : "0px"}
          width={isMobile ? "100%" : "auto"}
          bgcolor="#EFF8FF"
          mt={isMobile ? 6 : 0}
        >
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: "700",
              fontSize: isTab ? "24px" : "48px",
              lineHeight: isTab ? "36px" : "62.6px",
              marginBottom: "20px",
            }}
          >
            Send us Message
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: isMobile ? "0px" : "15px",
                background: "#fff",
              }}
            >
              <input
                placeholder="Full Name*"
                value={name}
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  padding: isMobile ? "6px" : "10px 10px",
                  width: "100%",
                  fontSize: isTab ? "14px" : "20px",
                }}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: isMobile ? "15px" : "15px",
                background: "#fff",
              }}
            >
              <input
                placeholder="Phone Number*"
                value={contact}
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  padding: isMobile ? "6px" : "10px 10px",
                  width: "100%",
                  fontSize: isTab ? "14px" : "20px",
                }}
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              padding: "5px 20px 5px 10px",
              marginBottom: "15px",
              background: "#fff",
            }}
          >
            <input
              placeholder="Email*"
              value={email}
              style={{
                ...classes.input,
                fontFamily: "Outfit",
                padding: isMobile ? "6px" : "10px 10px",
                width: "100%",
                fontSize: isTab ? "14px" : "20px",
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              padding: "5px 20px 5px 10px",
              marginBottom: "15px",
              background: "#fff",
              alignItems: "flex-start",
            }}
          >
            <textarea
              placeholder="Message"
              value={message}
              style={{
                ...classes.input,
                fontFamily: "Outfit",
                padding: isMobile ? "6px" : "10px 10px",
                width: "100%",
                height: isTab ? "130px" : "240px",
                fontSize: isTab ? "14px" : "20px",
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: isTab ? "auto" : "fit-content",
              height: isTab ? "40px" : "50px",
              background: "#1377C0",
              borderRadius: "8px",
              color: "#fff",
              fontWeight: "700",
              marginTop: "10px",
              fontSize: isTab ? "12px" : "14px",
              display: "flex",
              alignItems: "center",
              margin: isTab ? "auto" : "0px",
            }}
            disabled={isLoading}
            onClick={sendEnquiry}
          >
            SEND MESSAGE US
            {isLoading ? (
              <>
                <svg
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 100 100"
                  enable-background="new 0 0 0 0"
                >
                  <path
                    fill="#fff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </>
            ) : (
              <>
                <span style={{ fontSize: "30px", padding: "0px 0px 6px 6px" }}>
                  →
                </span>
              </>
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contacts;
